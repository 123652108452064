/** @jsxImportSource @emotion/react */
import moment from "moment";
import _ from "lodash";
import { PropTypes } from "prop-types";
import { useCallback, useEffect } from "react";
import { FormControl } from "react-bootstrap";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { getDateFormatFromUserPreferences } from "utils/date-time";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import SelectField from "components-old/forms/fields/SelectField";
import { PriorityFilterDropdown } from "./DamageView.PriorityFilterDropdown";
import { DamageFormAsyncDropdown } from "./DamageFormAsyncDropdown";
import {
  PhoneNumberInput,
  isValidPhoneNumber,
} from "components/molecules/PhoneInput.molecule";
import Colors from "styles/colors";
import { useDamageViewFormLabelTranslation } from "pages/damageview/utils/translation.utils";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "utils/validation-utils";

const TextNote = ({ textContent, isValidated, showCheckbox }) => {
  return (
    <Text
      block
      css={{
        marginTop: "5px",
        color:
          showCheckbox && isValidated
            ? `${Colors.text.GREEN}`
            : `${Colors.text.DIM_GRAY}`,
      }}
    >
      {showCheckbox ? <Icon src={faCheckCircle} /> : null} {textContent}
    </Text>
  );
};

TextNote.propTypes = {
  textContent: PropTypes.string,
  isValidated: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

export const FieldComponents = ({
  field,
  setFieldData,
  isSubfield,
  fieldData,
  step,
  setDefaultValue = true,
  updateAsyncData = null,
  shipper = null,
}) => {
  const {
    getTranslatedAreaCode,
    getTranslatedTypeCode,
    getTranslatedSeverityCode,
    getTranslatedFormTextContext,
  } = useDamageViewFormLabelTranslation();

  const { t } = useTranslation("damage-view");

  const validateAdditionalEmailsField = useCallback((value) => {
    let emailString = value.trim().replace(/ /g, " ");

    if (emailString === "") {
      return false;
    }

    let delimiter = "";
    if (emailString.includes(";")) {
      delimiter = ";";
    } else if (emailString.includes(",")) {
      delimiter = ",";
    } else {
      return isValidEmail(emailString);
    }

    if (emailString.includes(";") && emailString.includes(",")) {
      console.error(
        "Invalid input: Use either semicolon or space as delimiter, not both.",
      );
      return false;
    }

    const foundEmails = [];
    const emails = emailString
      .split(delimiter)
      .map((email) => email.trim()) //trim email
      .filter((email) => !_.isEmpty(email)); //filter empty values after adding DELIMITER

    emails.forEach((email) => {
      if (isValidEmail(email) === true) {
        foundEmails.push({ email: email, valid: true });
      }
    });

    return foundEmails.length === emails.length;
  }, []);

  const renderFieldByType = (
    field,
    fieldData,
    setFieldData,
    step,
    updateAsyncData,
    dateFormat,
    t,
  ) => {
    if (field.valueType === "dropdown") {
      const dropdownOptions = field.values?.map((data) => {
        if (field?.fieldName === "area") {
          return {
            label: getTranslatedAreaCode(data.value),
            value: data.value,
          };
        } else if (field?.fieldName === "type") {
          return {
            label: getTranslatedTypeCode(data.value),
            value: data.value,
          };
        } else if (field?.fieldName === "severity") {
          return {
            label: getTranslatedSeverityCode(data.value),
            value: data.value,
          };
        }

        return {
          label: data.label,
          value: data.value,
        };
      });
      if (isSubfield && !field.isAsync) {
        return (
          <PriorityFilterDropdown
            field={field}
            options={dropdownOptions}
            setFieldData={setFieldData}
            stateValue={fieldData[field.fieldName]}
          />
        );
      } else if (!field.isAsync) {
        return (
          <SelectField
            css={{
              width: "60%",
            }}
            isSearchable={true}
            placeholder={""}
            isMulti={false}
            stateValue={fieldData[field.fieldName]}
            onChange={(data) => {
              setFieldData(field.fieldName, data.value);
            }}
            options={dropdownOptions}
          />
        );
      } else {
        return (
          <div css={{ width: "60%" }}>
            <DamageFormAsyncDropdown
              keyValue={step}
              selfPagination={field?.fieldName === "vin"}
              id={field.fieldName}
              qsp={"query"}
              endpoint={
                shipper && field?.fieldName === "reportingLocationId"
                  ? `${field.endpoint}&solutions=${shipper}`
                  : field.endpoint
              }
              isCountVersion={field?.isCountVersion ?? false}
              value={fieldData[field.fieldName]}
              onChange={(data) => {
                setFieldData(field.fieldName, data);
              }}
              valueSetter={(res, pageNumber, inputValue) => {
                const data = res?.data?.data?.map((val) => {
                  return {
                    label:
                      field.fieldName === "reportingLocationId"
                        ? `${val?.name} (${val?.code})`
                        : val.id,
                    value: val.id,
                  };
                });
                const metaData = res?.data?.meta;
                return {
                  data: updateAsyncData
                    ? updateAsyncData(data, pageNumber, inputValue)
                    : data,
                  metaData,
                };
              }}
            />
            {field?.textContext ? (
              <TextNote
                textContent={getTranslatedFormTextContext(field?.textContext)}
              />
            ) : null}
          </div>
        );
      }
    } else if (field.valueType === "input" || field.valueType === "email") {
      return (
        <div
          css={{
            minWidth: "60%",
            width: "60%",
          }}
        >
          <FormControl
            value={fieldData[field.fieldName]}
            onChange={(event) => {
              setFieldData(field.fieldName, event.target.value);
            }}
          />
          {field?.fieldName === "secondarySubmitterEmail" ? (
            <TextNote
              textContent={t("damage-view:Email addresses must be different")}
              showCheckbox={true}
              isValidated={
                fieldData[field.fieldName] &&
                isValidEmail(fieldData[field.fieldName]) &&
                fieldData?.submitterEmail &&
                isValidEmail(fieldData.submitterEmail) &&
                fieldData?.submitterEmail !== fieldData[field.fieldName]
              }
            />
          ) : null}
        </div>
      );
    } else if (field.valueType === "phone") {
      return (
        <div
          css={{
            width: "60%",
            minWidth: "60%",
          }}
        >
          <PhoneNumberInput
            defaultCountry={"US"}
            value={fieldData[field.fieldName]}
            onChange={(value) => {
              setFieldData(field.fieldName, value);
            }}
          />

          {field?.fieldName === "secondarySubmitterPhoneNumber" ? (
            <TextNote
              textContent={t("damage-view:Phone numbers must be different")}
              showCheckbox={true}
              isValidated={
                fieldData[field.fieldName] &&
                isValidPhoneNumber(fieldData[field.fieldName]) &&
                fieldData?.submitterPhoneNumber &&
                isValidPhoneNumber(fieldData.submitterPhoneNumber) &&
                fieldData?.submitterPhoneNumber !== fieldData[field.fieldName]
              }
            />
          ) : null}

          {field?.textContext ? (
            <TextNote
              textContent={getTranslatedFormTextContext(field?.textContext)}
            />
          ) : null}
        </div>
      );
    } else if (field.valueType === "date") {
      return (
        <div
          css={{
            width: "65%",
          }}
        >
          <DateTimePicker
            css={{
              // hiding the time icon since we need only the date picker
              width: "100%",
              ".rw-widget-container": {
                ".rw-select-bordered": {
                  "button:nth-of-type(2)": {
                    display: "none",
                  },
                },
              },
            }}
            format={dateFormat}
            value={fieldData[field.fieldName]}
            onChange={(value) => {
              setFieldData(
                field.fieldName,
                moment(value).startOf("day").toDate(),
              );
            }}
          />
        </div>
      );
    } else if (field.valueType === "textarea") {
      return (
        <div>
          <FormControl
            required={true}
            as="textarea"
            rows="3"
            css={{
              width: "100%",
            }}
            value={fieldData[field.fieldName]}
            onChange={(event) => {
              setFieldData(field.fieldName, event.target.value);
            }}
          />
          {field?.fieldName === "additionalEmails" ? (
            <TextNote
              textContent={t(
                "damage-view:Email addresses should be separated by commas or semicolons",
              )}
              showCheckbox={true}
              isValidated={
                fieldData[field.fieldName] &&
                validateAdditionalEmailsField(fieldData[field.fieldName]) &&
                fieldData?.additionalEmails
              }
            />
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  const dateFormat = getDateFormatFromUserPreferences();

  useEffect(() => {
    if (setDefaultValue) {
      if (
        field.valueType === "dropdown" &&
        !field.isAsync &&
        !fieldData[field.fieldName]
      ) {
        setFieldData(field.fieldName, field.values[0]?.value);
      } else if (
        field.valueType === "date" &&
        field.required &&
        !fieldData[field.fieldName]
      ) {
        setFieldData(field.fieldName, moment().startOf("day").toDate());
      }
    }
  }, [setFieldData, field, fieldData, setDefaultValue]);

  return renderFieldByType(
    field,
    fieldData,
    setFieldData,
    step,
    updateAsyncData,
    dateFormat,
    t,
  );
};

FieldComponents.propTypes = {
  field: PropTypes.object,
  setFieldData: PropTypes.func,
  fieldData: PropTypes.object,
  step: PropTypes.number,
  setDefaultValue: PropTypes.bool,
  updateAsyncData: PropTypes.func,
  shipper: PropTypes.string,
};
