/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Colors from "styles/colors";
import { formatTimestamp, convertToMMDDYYYY } from "utils/date-time";

const rowStyle = css({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  fontWeight: "bold",
  color: Colors.text.DARK,
  alignItems: "center",
  padding: "0.1em 0",
});

const labelStyle = css({
  fontWeight: "bold",
  flex: "0 0 auto",
  marginRight: "0.5em",
  color: Colors.text.DARK,
});

const valueStyle = css({
  fontWeight: "normal",
  color: Colors.text.DARK,
  flex: 1,
});

export const FeaturedReference = ({ featuredReferences = [] }) => {
  return (
    <>
      {featuredReferences.map((ref, index) => {
        let displayValue = ref.value ?? "";
        if (ref.datatype === "datetime" && displayValue) {
          displayValue = convertToMMDDYYYY(
            formatTimestamp(displayValue)?.date || "",
          );
        }

        return (
          <div key={ref.id || index} css={rowStyle}>
            <span css={labelStyle}>{`${ref.label} - `}</span>
            <span css={valueStyle}>{displayValue}</span>
          </div>
        );
      })}
    </>
  );
};

FeaturedReference.propTypes = {
  featuredReferences: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      datatype: PropTypes.string,
    }),
  ).isRequired,
};
