/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Colors from "styles/colors";
import { MediaQueries } from "components/responsive";

const featuredReferencesContainer = css({
  background: Colors.background.GRAY,
  padding: "1em",
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "0.8em",
  width: "100%",
  marginTop: "1.5em",
  [MediaQueries.small]: {
    padding: "0.5em",
    fontSize: "0.65em",
  },
});

const featuredReferenceRow = css({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  fontWeight: "bold",
  color: Colors.text.DARK,
  alignItems: "center",
  padding: "0.1em 0",
});

const labelStyle = css({
  fontWeight: "bold",
  flex: "0 0 auto",
  marginRight: "0.5em",
  color: Colors.text.DARK,
});

const valueStyle = css({
  fontWeight: "normal",
  color: Colors.text.DARK,
  flex: 1,
});

export const FeaturedReferenceDetails = ({ featuredReferences }) => (
  <div css={featuredReferencesContainer}>
    {featuredReferences.map((reference, index) => (
      <div key={index} css={featuredReferenceRow}>
        <span css={labelStyle}>{reference.label}:</span>
        <span css={valueStyle}>{reference.value}</span>
      </div>
    ))}
  </div>
);

FeaturedReferenceDetails.propTypes = {
  featuredReferences: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      datatype: PropTypes.string,
    }),
  ).isRequired,
};
