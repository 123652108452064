import moment from "moment";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

import { SEARCH_CATEGORIES } from "pages/finishedvehicle/search/FinVehicleSearchCategoryDefs";
import { FILTERS } from "pages/finishedvehicle/search/FinVehicleSearchFilterDefs";
import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "fvSearch";

// H1-1594 Add lifecycle state to all VIN searches
const addLifeCycleParam = (qs) => {
  if (qs.includes("lifeCycleState")) {
    return qs;
  }

  // Include the default lifeCycleState if not already specified by a filter
  return `${qs}&lifeCycleState=Active,Delivered,Complete,Prebuilt`;
};

const addProductTypeParam = (qs) => {
  if (qs.includes("productType")) {
    return qs;
  }
  return `${qs}&productType=FinishedVehicle`;
};

const entitiesUrl = (solutionId, queryString) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(
    `/entity/solution/${solutionId}/entity?includeConfigurations=true&${qs}`,
  );
};

const batchSearchUrl = (solutionId, queryString, batchType) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(
    `/entity/solution/${solutionId}/batch-search?${qs}&batchType=${batchType}`,
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
    },
  };
};

/**
 * Helper function that will combine separate `groupCategory:<groupType>` params.
 *
 * e.g. `groupCategory:ITSS=100&groupCategory:SB=101` => `groupCategory=100,101`
 */
export const formatGroupCategoryParamInQueryString = (queryString) => {
  // Parse the query params
  const queryParams = qs.parse(queryString);

  // Pull out the separate `groupCategory` params.
  const categoriesForItss = queryParams["groupCategory:ITSS"];
  const categoriesForSpotBuy = queryParams["groupCategory:SB"];

  // Combine them in one list if both exist. Or set to one of the groupCategory params.
  let groupCategoryValue;

  if (categoriesForItss && categoriesForSpotBuy) {
    groupCategoryValue = categoriesForItss + "," + categoriesForSpotBuy;
  } else if (categoriesForItss) {
    groupCategoryValue = categoriesForItss;
  } else if (categoriesForSpotBuy) {
    groupCategoryValue = categoriesForSpotBuy;
  }

  // Remove the temporary query params.
  delete queryParams["groupCategory:ITSS"];
  delete queryParams["groupCategory:SB"];

  // Add the actual groupCategory param.
  queryParams.groupCategory = groupCategoryValue;

  return qs.stringify(queryParams);
};

// Actions

const FETCH_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/FETCH_TOTAL_COUNT_FOR_SEARCH`;
const RECEIVE_TOTAL_COUNT_FOR_SEARCH = `${STORE_MOUNT_POINT}/RECEIVE_TOTAL_COUNT_FOR_SEARCH`;

const fetchSearch = (
  queryString = "",
  solutionId,
  duck,
  dispatch,
  state,
  preventRedirect,
) => {
  queryString = formatGroupCategoryParamInQueryString(queryString);

  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    batchSearch(solutionId, queryString, dispatch, batchFilter, duck);
  } else {
    // Normal search GET
    const url = entitiesUrl(solutionId, queryString);
    const config = axiosConfig();

    // Fetch the search
    dispatch(duck.fetch(url, config));

    // Fetch the count
    dispatch(fetchTotalCount(solutionId, queryString, config));

    // Prevent redirect if needed
    if (!preventRedirect) {
      dispatch({ type: "VIN_SEARCH" });
    }
  }
};

const batchSearch = (solutionId, queryString, dispatch, batchFilter, duck) => {
  const batchType = batchFilter.batch_type;
  const url = batchSearchUrl(solutionId, queryString, batchType);
  const data = {
    batch_list: batchFilter.batch_list,
  };
  const config = axiosConfig();

  dispatch({
    type: duck.actions.REQUEST,
  });

  axios
    .post(url, data, config)
    .then((response) => {
      dispatch({
        type: duck.actions.RECEIVE,
        payload: response.data ? response.data : [],
      });
      dispatch({ type: "VIN_SEARCH" });
    })
    .catch((err) => {
      dispatch({
        type: duck.actions.REQUEST_ERROR,
        err,
      });
    });

  dispatch(fetchTotalCount(solutionId, queryString, config, batchType, data));
};

const fetchTotalCount = (solutionId, queryString, config, batchType, data) => {
  return (dispatch, getState) => {
    const state = getState();
    // Fetch the count (and remove pagination params)
    const countParams = qs.parse(queryString);
    countParams.pageNumber = undefined;
    countParams.pageSize = undefined;
    const countQueryString = qs.stringify(countParams);

    const url = batchType
      ? batchSearchUrl(solutionId, countQueryString, batchType)
      : entitiesUrl(solutionId, countQueryString);

    dispatch({ type: FETCH_TOTAL_COUNT_FOR_SEARCH });

    // Get page size to calculate the total pages
    const pageSize = state[STORE_MOUNT_POINT].pageSize;

    axios({
      ...config,
      method: batchType ? "POST" : "GET",
      url,
      data: batchType ? data : undefined,
      headers: {
        ...config.headers,
        Accept: "application/json;version=count",
      },
    })
      .then((response) => {
        const count = response.data?.meta?.totalCount;
        dispatch({
          type: RECEIVE_TOTAL_COUNT_FOR_SEARCH,
          count,
          totalPages: _.ceil(count / pageSize),
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// Selectors
const getTotalCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearch || 0;

const getTotalPageCountForSearch = (state) =>
  state[STORE_MOUNT_POINT].totalPageCountForSearch || 0;

const getTotalCountForSearchIsLoading = (state) =>
  state[STORE_MOUNT_POINT].totalCountForSearchIsLoading || false;

// Reducer
const finVehicleSearchBarReducer = (
  state = {
    totalCountForSearch: 0,
    totalPageCountForSearch: 0,
    totalCountForSearchIsLoading: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearchIsLoading: state.totalCountForSearch ? false : true,
      };
    case RECEIVE_TOTAL_COUNT_FOR_SEARCH:
      return {
        ...state,
        totalCountForSearch: action.count,
        totalPageCountForSearch: action.totalPages,
        totalCountForSearchIsLoading: false,
      };
    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [finVehicleSearchBarReducer],
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    entitiesUrl,
    batchSearchUrl,
    { headers: { Accept: "text/csv;version=full" } },
    "fin-vehicle-search-results",
  ),
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getTotalCountForSearch,
  getTotalPageCountForSearch,
  getTotalCountForSearchIsLoading,
};

export default SearchBarState;
